<template>
  <div>
    <van-list
      v-model="loading"
      :finished="finished"
      :offset="15"
      @load="onLoad"
    >
      <div v-if="flag === true">
        <div
          v-for="(item, i) in list"
          :key="i"
          class="weui-cells"
          style="margin-top: 10px;"
        >
          <a
            class="weui-cell weui-cell_access"
            href="javascript:;"
            @click="
              toBpmInfo(
                item.PK_Value,
                item.IST_ID,
                item.SEQ_ID,
                item.Node_ID,
                item.Flow_ID
              )
            "
          >
            <div class="weui-media-box__hd">
              <img src="" alt="" style="width:30px;margin-right:5px;" />
            </div>
            <div class="weui-cell__bd">
              <p>
                任务名称：
                <span>{{ item.Node_Title }}</span>
                <br />
                生成时间：
                <span>{{ item.Create_Time }}</span>
              </p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
        </div>
      </div>
      <van-empty v-else description="暂无待办任务" />
    </van-list>
  </div>
</template>

<script>
  import store from '@/store'
  import Base64 from '@/util/Base64'
  import { getIstList } from '@/api/ist/ist/ist'
  import { Emas } from '@/assets/js/Emas'
  import { Dialog } from 'vant'
  export default {
    name: 'Index',
    data() {
      return {
        User_ID: '',
        token: '',
        list: [],
        flag: true,
        loading: false, //是否处于加载状态
        finished: false, //是否已加载完所有数据
        isFinished: false,
        pageCount: '', //总页数
        PageNo: 1,
        PageSize: 15,
      }
    },
    created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      this.User_ID = userInfo.User_ID
      let teamInfo = JSON.parse(store.getters['user/teamInfo'])
      this.fetchData()
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(staffName, userId, 'ist', '城管-代办任务列表', 'zzdcg.yy.gov.cn/ist')
    },
    methods: {
      onLoad() {
        //加载时页面数量加一
        this.loading = true
        //当页面数大于总页数时，停止加载请求数据
        if (this.PageNo >= this.pageCount) {
          this.loading = false
          this.isFinished = true //数据加载完
        } else {
          this.PageNo++
          console.log(this.PageNo)
          this.fetchData()
        }
      },
      //获取待办事项
      async fetchData() {
        const { data } = await getIstList({
          UserID: this.User_ID,
          PageNo: this.PageNo,
          PageSize: this.PageSize,
          Title: '',
        })
        if (data.data.length > 0) {
          this.flag = true
          this.pageCount = data.pageCount
          this.list = this.list.concat(data.data)
          this.loading = false
        } else {
          this.flag = false
          this.loading = false //不处于加载状态
          this.isFinished = true //数据加载完，不会再处于加载状态
        }
      },
      toBpmInfo(PK_Value, IST_ID, SEQ_ID, Node_ID, Flow_ID) {
        let url = ''
        if (Flow_ID !== 84) {
          if (Node_ID == 2) {
            url = '/istXzNqSp'
            //url = '/istXzWgSp'
          } else {
            url = '/istXzWgSp'
          }
          this.$router.push({
            path: url,
            query: {
              pkValue: Base64.encode(PK_Value),
              istID: Base64.encode(IST_ID),
              seqID: Base64.encode(SEQ_ID),
              nodeID: Base64.encode(Node_ID),
            },
          })
        } else if (Flow_ID == 89) {
          if (Node_ID == 2) {
            url = '/istEventWgySp'
          } else {
            url = '/istEventSp'
          }
          this.$router.push({
            path: url,
            query: {
              pkValue: Base64.encode(PK_Value),
              istID: Base64.encode(IST_ID),
              seqID: Base64.encode(SEQ_ID),
              nodeID: Base64.encode(Node_ID),
            },
          })
        } else {
          Dialog.alert({
            title: '提示',
            message: '此流程请在电脑端审批！',
          }).then(() => {
            // on close
          })
        }
      },
    },
  }
</script>

<style scoped>
  @import '../../../assets/css/jquery-weui.css';
  @import '../../../assets/css/weui.css';
  @import '../../../assets/css/demos.css';
  .weui-media-box__title-after {
    color: #9b9b9b;
    font-size: 0.65rem;
    float: right;
  }

  .weui-cell__ft::after {
    content: ' ';
    display: inline-block;
    height: 6px;
    width: 6px;
    border-width: 2px 2px 0 0;
    border-color: #c8c8cd;
    border-style: solid;
    -webkit-transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    position: relative;
    top: -2px;
    position: absolute;
    top: 50%;
    margin-top: -4px;
    right: 20px;
  }
  .weui-cell__bd span {
    color: #5288f5;
  }
</style>
